<template>
  <v-select
    label="Name"
    class="w-full"
    :show-no-options="false"
    :show-no-results="false"
    :options="locations"
    v-model="selectedLocation"
    @input="onLocationSelect(selectedLocation)"
    :clearable="false"
    placeholder="Selecione uma opção"
  >
    <template slot="option" slot-scope="option">
      <span class="" :class="[option.Icon, 'icon-font']"></span>
      {{ option.Name }}
    </template>
  </v-select>
</template>

<script>
export default {
  name: "location-dropdown",
  data() {
    return {
      selectedLocation: undefined,
      locations: [
        {
          Id: 1,
          Name: "Lista de propostas",
          Url: "propostas",
          Icon: "onpoint-bookmark-simple"
        },
        {
          Id: 2,
          Name: "Lista de apólices",
          Url: "apolices",
          Icon: "onpoint-note-pencil"
        }
      ],
      currentRoute: this.$route.name
    };
  },
  beforeMount() {
    this.selectedLocation = this.locations.find(
      x => x.Url == this.currentRoute
    );
  },
  methods: {
    onLocationSelect(location) {
      if (location.Url != this.currentRoute) {
        this.$router.push(`/${location.Url}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .vs__dropdown-toggle {
  background: white;
  border: none;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.icon-font::before{
  position: relative;
  top: 2px;
}
</style>
